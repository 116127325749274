<template>
  <!--      Start  Form -->
  <v-dialog
    v-if="entity"
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-btn
          icon
          :loading="processing"
          :disabled="processing"
          @click="close"
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{$t('model.api_client.api_client') +' ('+(!editMode ?  $t('base.addNew') : $t('base.edit'))+')'}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <validation-observer
          ref="form"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            @submit.prevent="handleSubmit(onSave)"
            @reset.prevent="reset"
          >
            <v-container>
              <v-row>

                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.api_client.api_name')"
                    rules="required|max:100"
                  >
                    <v-text-field
                      prepend-icon="mdi-pencil"
                      v-model="entity.api_name"
                      :error-messages="errors"
                      :placeholder="$t('model.api_client.api_name')"
                      :label="$t('model.api_client.api_name')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="entity.by_pass"
                    :label="entity.by_pass ? $t('model.api_client.by_pass')+' '+$t('base.enable') : $t('model.api_client.by_pass')+' '+$t('base.disable')"
                  ></v-switch>
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="entity.status"
                    :label="entity.status ? $t('base.enable') : $t('base.disable')"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="$emit('onrefreshtoken')"
                    :loading="processing"
                    :disabled="processing"
                  >RefreshToken</v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="mt-6"
                  align="center"
                >
                  <v-btn
                    text
                    @click="close"
                    :disabled="processing"
                  >
                    {{ $t("base.cancel") }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="primary"
                    text
                    :disabled="processing"
                  >
                    <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";
export default defineComponent({
  props: {
    value: null,
    open: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const entity = reactive(props.value);
    const close = () => {
      emit("close");
    };
    const onSave = () => {
      emit("save", entity);
    };
    return {
      entity,
      close,
      onSave
    };
  }
});
</script>
